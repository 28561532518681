import React, { useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import MobileDropdownRow from "./components/mobileDropdownRow/MobileDropdownRow";
import IconButton from "../buttons/iconButton";
import { Link } from "react-router-dom";

export default function Header() {
    const [solutionsDropdownOpen, setSolutionsDropdownOpen] = useState(false);
    const [mobileDropdownOpen, setMobileDropdownOpen] = useState(false);
    const [mobileSolutionsDropdownOpen, setMobileSolutionsDropdownOpen] =
        useState(false);

    const navDivRef = useRef(null);
    const navDivCollapse = useRef(null);
    const navDivMobileRef = useRef(null);
    const navDivMobileCollapse = useRef(null);
    const navDivMobileClose = useRef(null);

    const closeDropdown = (e) => {
        if (
            (solutionsDropdownOpen ||
                mobileDropdownOpen ||
                mobileSolutionsDropdownOpen) &&
            !navDivRef?.current?.contains(e.target) &&
            !navDivCollapse?.current?.contains(e.target) &&
            !navDivMobileRef?.current?.contains(e.target) &&
            !navDivMobileCollapse?.current?.contains(e.target) &&
            !navDivMobileClose?.current?.contains(e.target)
        ) {
            CloseNavDropDown();
        }
    };

    document.body.addEventListener("mousedown", closeDropdown);

    function CloseNavDropDown() {
        setSolutionsDropdownOpen(false);
        setMobileSolutionsDropdownOpen(false);
        setMobileDropdownOpen(false);
    }

    return (
        <div>
            <Navbar
                expand="md"
                className="bg-white cj-blue-sky cj-navbar mx-4 mx-xl-5"
            >
                <div className="ml-auto">
                    <Navbar.Brand href="/" className="">
                        <img
                            id="nav-cj-logo"
                            src="/img/cj-logo.svg"
                            alt="Charles Jones Logo"
                        />
                    </Navbar.Brand>
                </div>

                <div className="ms-auto d-md-block d-none" ref={navDivRef}>
                    <Navbar.Collapse>
                        <Nav>
                            <div className="header-nav-link my-auto mx-xl-1 nav-item show dropdown">
                                <button
                                    id="solutions-dropdown"
                                    className={
                                        solutionsDropdownOpen
                                            ? "dropdown-toggle-inverted dropdown-toggle show nav-link"
                                            : "dropdown-toggle show nav-link"
                                    }
                                    onClick={() =>
                                        setSolutionsDropdownOpen(
                                            (previousState) => !previousState
                                        )
                                    }
                                >
                                    Solutions
                                </button>
                                <div
                                    aria-labelledby="solutions-dropdown"
                                    data-bs-popper="static"
                                    className="dropdown-menu show"
                                ></div>
                            </div>

                            <Nav.Link
                                as={Link}
                                to="/faq"
                                className="header-nav-link my-auto mx-xl-1"
                                onClick={() => CloseNavDropDown()}
                            >
                                FAQ
                            </Nav.Link>

                            <Nav.Link
                                as={Link}
                                className="header-nav-link my-auto mx-xl-1"
                                to="/about"
                                onClick={() => CloseNavDropDown()}
                            >
                                Company
                            </Nav.Link>

                            <Nav.Link
                                as={Link}
                                to="/contact-us"
                                className="header-nav-link my-auto mx-xl-1"
                                onClick={() => CloseNavDropDown()}
                            >
                                Contact
                            </Nav.Link>
                            <form action="/SignOn/login.roc" method="POST">
                                <Button
                                    className="btn btn-nav btn-cj-secondary ms-3"
                                    type="submit"
                                >
                                    SIGN&nbsp;
                                    <span className="sign-on-span">ON</span>
                                </Button>
                            </form>

                            <a href="/get-started">
                                <Button
                                    className="btn btn-nav btn-cj-primary ms-3"
                                    type="button"
                                >
                                    GET STARTED
                                </Button>
                            </a>
                        </Nav>
                    </Navbar.Collapse>
                </div>
                <div ref={navDivMobileClose}>
                    <button
                        id="navbar-toggle"
                        type="button"
                        aria-label="Toggle navigation"
                        className="navbar-toggler collapsed"
                        style={
                            mobileDropdownOpen
                                ? { backgroundColor: "transparent" }
                                : null
                        }
                        onClick={() =>
                            setMobileDropdownOpen(
                                (previousState) => !previousState
                            )
                        }
                    >
                        <span
                            className={
                                mobileDropdownOpen
                                    ? "navbar-toggler-icon navbar-exit-icon"
                                    : "navbar-toggler-icon"
                            }
                        ></span>
                    </button>
                </div>
            </Navbar>

            {mobileDropdownOpen ? (
                <div className="d-md-none d-block me-5">
                    <Container fluid className="desktop-dropdown p-0">
                        <div ref={navDivMobileRef}>
                            <MobileDropdownRow
                                name="Solutions"
                                style={{
                                    borderTop: "1px solid rgba(0, 0, 0, 0.2)",
                                }}
                                onClick={() =>
                                    setMobileSolutionsDropdownOpen(
                                        (previousState) => !previousState
                                    )
                                }
                                showGoBackNavigation={
                                    mobileSolutionsDropdownOpen
                                }
                            />
                        </div>
                        {mobileSolutionsDropdownOpen ? (
                            <div ref={navDivMobileCollapse}>
                                <div className="blue-nav-card m-4">
                                    <h2 className="nav-card-header mb-3">
                                        Solutions
                                    </h2>
                                    <p className="nav-card-text">
                                        You won’t find a regional public record
                                        firm serving the title, legal ..
                                    </p>
                                    <a href="/solutions">
                                        <IconButton
                                            text="LEARN MORE"
                                            styleClass="btn-cj-secondary learn-more-button"
                                            iconClass="btn-arrow-right learn-more-arrow"
                                            style={{ paddingBottom: "15px" }}
                                        />
                                    </a>
                                </div>
                                <MobileDropdownRow
                                    name="Name Searches"
                                    navigateTo={"/solutions/name-searches"}
                                    onClick={() => CloseNavDropDown()}
                                />
                                <MobileDropdownRow
                                    name="Tax Searches"
                                    navigateTo={"/solutions/tax-searches"}
                                    onClick={() => CloseNavDropDown()}
                                />
                                <MobileDropdownRow
                                    name="Flood and Tideland Services"
                                    navigateTo={"/solutions/flood-and-tideland"}
                                    onClick={() => CloseNavDropDown()}
                                />
                                <MobileDropdownRow
                                    name="County Search Services"
                                    navigateTo={"/solutions/county-search"}
                                    onClick={() => CloseNavDropDown()}
                                />
                                <MobileDropdownRow
                                    name="Corporate and UCC Services"
                                    navigateTo={"/solutions/corporate-and-ucc"}
                                    onClick={() => CloseNavDropDown()}
                                />
                                <MobileDropdownRow
                                    name="Nationwide Services"
                                    navigateTo={
                                        "/solutions/nationwide-services"
                                    }
                                    onClick={() => CloseNavDropDown()}
                                />
                            </div>
                        ) : (
                            <div ref={navDivMobileCollapse}>
                                <MobileDropdownRow
                                    name="FAQ"
                                    navigateTo={"/faq"}
                                    onClick={() => CloseNavDropDown()}
                                />
                                <MobileDropdownRow
                                    name="Company"
                                    navigateTo={"/about"}
                                    onClick={() => CloseNavDropDown()}
                                />
                                <MobileDropdownRow
                                    name="Contact"
                                    navigateTo={"/contact-us"}
                                    onClick={() => CloseNavDropDown()}
                                />
                            </div>
                        )}
                    </Container>
                </div>
            ) : (
                <></>
            )}

            {solutionsDropdownOpen ? (
                <div className="d-md-block d-none me-5" ref={navDivCollapse}>
                    <Container fluid className="desktop-dropdown p-5">
                        <Row>
                            <Col id="solution-card" className="mx-2">
                                <div className="blue-nav-card">
                                    <h2 className="nav-card-header mb-3">
                                        Solutions
                                    </h2>
                                    <p className="nav-card-text">
                                        You won’t find a regional public record
                                        firm serving the title, legal and
                                        lending markets with the resources and
                                        expertise of Charles Jones.
                                    </p>

                                    <p className="nav-card-text mb-4">
                                        We pride ourselves on delivering high
                                        quality due diligence solutions to
                                        assist with real estate settlements in
                                        NJ and PA.
                                    </p>

                                    <a href="/solutions">
                                        <IconButton
                                            text="LEARN MORE"
                                            styleClass="btn-cj-secondary learn-more-button"
                                            iconClass="btn-arrow-right learn-more-arrow"
                                            style={{ paddingBottom: "15px" }}
                                        />
                                    </a>
                                </div>
                            </Col>
                            <Col className="mx-2">
                                <div>
                                    <Nav.Link
                                        href="/solutions/name-searches"
                                        className="nav-card-category">
                                        Name Searches
                                    </Nav.Link>
                                    <Nav.Link
                                        href="/solutions/name-searches/child-support-judgment"
                                        className="nav-card-link my-2"
                                    >
                                        Child Support Judgment Search
                                    </Nav.Link>
                                    <Nav.Link
                                        href="/solutions/name-searches/judgment-lien-search"
                                        className="nav-card-link my-2"
                                    >
                                        Judgment Lien Search
                                    </Nav.Link>

                                    <Nav.Link
                                        href="/solutions/name-searches/patriot-name-search"
                                        className="nav-card-link my-2"
                                    >
                                        Patriot Name Search
                                    </Nav.Link>

                                    <Nav.Link
                                        href="/solutions/flood-and-tideland"
                                        className="nav-card-category">
                                        Flood and Tideland Services
                                    </Nav.Link>
                                    <Nav.Link
                                        href="/solutions/flood-and-tideland/flood-search-determination"
                                        className="nav-card-link my-2"
                                    >
                                        Flood Search Determination
                                    </Nav.Link>
                                    <Nav.Link
                                        href="/solutions/flood-and-tideland/lol-flood-monitoring"
                                        className="nav-card-link my-2"
                                    >
                                        Life-of-Loan Flood Monitoring
                                    </Nav.Link>
                                    <Nav.Link
                                        href="/solutions/flood-and-tideland/tideland-claims-search"
                                        className="nav-card-link my-2"
                                    >
                                        Tideland Claims Search
                                    </Nav.Link>
                                    <Nav.Link
                                        href="/solutions/flood-and-tideland/tideland-grant-search"
                                        className="nav-card-link my-2"
                                    >
                                        Tideland Grant Search
                                    </Nav.Link>
                                    <Nav.Link
                                        href="/solutions/flood-and-tideland/wetland-determination"
                                        className="nav-card-link my-2"
                                    >
                                        Wetland Determination
                                    </Nav.Link>
                                </div>
                            </Col>
                            <Col className="mx-2">
                                <div>
                                    <Nav.Link
                                        href="/solutions/tax-searches"
                                        className="nav-card-category">
                                        Tax Searches
                                    </Nav.Link>

                                    <Nav.Link
                                        href="/solutions/tax-searches/nj-tax-and-assessment-searches"
                                        className="nav-card-link my-2"
                                    >
                                        Tax and Assessment Search
                                    </Nav.Link>
                                    <Nav.Link
                                        href="/solutions/tax-searches/pa-tax-certifications"
                                        className="nav-card-link my-2"
                                    >
                                        Tax and Utility Certification
                                    </Nav.Link>

                                    <Nav.Link
                                        href="/solutions/county-search"
                                        className="nav-card-category">
                                        County Search Services
                                    </Nav.Link>
                                    <Nav.Link
                                        href="/solutions/county-search/county-search"
                                        className="nav-card-link my-2"
                                    >
                                        County Search Services
                                    </Nav.Link>

                                    <Nav.Link
                                        href="/solutions/nationwide-services"
                                        className="nav-card-category">
                                        Nationwide Services
                                    </Nav.Link>
                                    <Nav.Link
                                        href="/solutions/nationwide-services/due-diligence"
                                        className="nav-card-link my-2"
                                    >
                                        Due Diligence Services
                                    </Nav.Link>
                                    <Nav.Link
                                        href="/solutions/nationwide-services/corp-filing-registered-agent"
                                        className="nav-card-link my-2"
                                    >
                                        Corporate Filing & Registered Agent
                                        Services
                                    </Nav.Link>
                                    <Nav.Link
                                        href="/solutions/nationwide-services/flood-search"
                                        className="nav-card-link my-2"
                                    >
                                        Flood Search
                                    </Nav.Link>
                                    <Nav.Link
                                        href="/solutions/nationwide-services/lol-flood-monitoring"
                                        className="nav-card-link my-2"
                                    >
                                        Life-of-Loan Flood Monitoring
                                    </Nav.Link>
                                    <Nav.Link
                                        href="/solutions/nationwide-services/patriot-name-search"
                                        className="nav-card-link my-2"
                                    >
                                        Patriot Name Search
                                    </Nav.Link>
                                </div>
                            </Col>
                            <Col className="mx-2">
                                <div>
                                    <Nav.Link
                                        href="/solutions/corporate-and-ucc"
                                        className="nav-card-category">
                                        Corporate and UCC Services
                                    </Nav.Link>
                                    <Nav.Link
                                        href="/solutions/corporate-and-ucc/status-report"
                                        className="nav-card-link my-2"
                                    >
                                        Status Report
                                    </Nav.Link>
                                    <Nav.Link
                                        href="/solutions/corporate-and-ucc/franchise-and-corporate-tax-lien-report"
                                        className="nav-card-link my-2"
                                    >
                                        Franchise Tax/Corporate Tax Lien Report
                                    </Nav.Link>
                                    <Nav.Link
                                        href="/solutions/corporate-and-ucc/good-standing"
                                        className="nav-card-link my-2"
                                    >
                                        Good Standing Certificate
                                    </Nav.Link>
                                    <Nav.Link
                                        href="/solutions/corporate-and-ucc/legal-existence"
                                        className="nav-card-link my-2"
                                    >
                                        Legal Existence (Good Standing)
                                        Certificate
                                    </Nav.Link>
                                    <Nav.Link
                                        href="/solutions/corporate-and-ucc/corporate-copies"
                                        className="nav-card-link my-2"
                                    >
                                        Corporate Copies
                                    </Nav.Link>{" "}
                                    <Nav.Link
                                        href="/solutions/corporate-and-ucc/ucc-plus"
                                        className="nav-card-link my-2"
                                    >
                                        UCC Plus
                                    </Nav.Link>{" "}
                                    <Nav.Link
                                        href="/solutions/corporate-and-ucc/ucc-state-report"
                                        className="nav-card-link my-2"
                                    >
                                        UCC State Report
                                    </Nav.Link>{" "}
                                    <Nav.Link
                                        href="/solutions/corporate-and-ucc/ucc-state-certified"
                                        className="nav-card-link my-2"
                                    >
                                        UCC State Certified
                                    </Nav.Link>{" "}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            ) : (
                <></>
            )}
        </div>
    );
}
